
  @import url('https://fonts.googleapis.com/css2?family=Arvo&family=Catamaran&family=Dancing+Script:wght@400;500&family=Dongle:wght@300&family=Island+Moments&family=Kanit:wght@600&family=Kdam+Thmor+Pro&family=Lobster&family=Merriweather:wght@300&family=Mochiy+Pop+P+One&family=Mukta:wght@200&family=Neonderthaw&family=Overlock:ital@1&family=Poppins:wght@200&family=Sedgwick+Ave&family=Source+Code+Pro:ital@1&family=Ubuntu:ital,wght@1,300&display=swap');

.companies-profile-child,
.companies-profile-item {
  position: absolute;
  top: 0;
  left: 0;
  background-color: var(--color-whitesmoke);
  width: 430px;
  height: 755px;
}
.companies-profile-item {
  top: 202px;
  left: 1174px;
  width: 350px;
}
.companies-frame-child {
  position: relative;
  width: 100px;
  height: 100px;
  object-fit: cover;
}
.companies-rectangle-group,
.companies-rectangle-parent {
  position: absolute;
  top: 120px;
  left: 50px;
  width: 208px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: var(--gap-9xs);
}
.companies-rectangle-group {
  top: 430px;
  left: 50px;
}
.companies-info{
position: relative;
color: var(--mid-green, #48B1A3);

/* H2 Consumer */
font-family: Nunito;
font-size: 40px;
font-style: normal;
font-weight: 400;
line-height: normal;
text-transform: capitalize;
/* padding: 0.rem; */
}
.companies-covered-areas {
  position: relative;
  
  color: #517FCC;
/* H3 Consumer */
font-family: Nunito;
font-size: 20px;
font-style: normal;
font-weight: 400;
line-height: normal;
text-transform: uppercase;
}
.companies-covered-areas {
  text-transform: uppercase;
}
.companies-lorem-ipsum-dolor {
  position: relative;
  display: inline-block;
  width: 745px;
  color: var(--no-dig-dark-blue, #011E34);

/* Body Text Consumer */
font-family: Catamaran;
font-size: 17px;

font-style: normal;
font-weight: 400;
line-height: 20px; /* 125% */
}
.companies-covered-areas-parent {
  width: 731px;
}
.companies-covered-areas-parent,
.companies-frame-parent,
.companies-info-parent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.companies-frame-parent {
  width: 720px;
  gap: var(--gap-base);
  font-size: var(--h4-consumer-size);
  color: var(--dark-purple);
}
.companies-info-parent {
  position: absolute;
  top: 339px;
  left: 433px;
  background-color: var(--white);
  border: 1px solid var(--color-gainsboro-200);
  box-sizing: border-box;
  width: 739px;
  padding: var(--padding-xs);
  gap: var(--gap-5xs);
  font-size: var(--h2-consumer-size);
  padding: 0.6rem;
}
.companies-group-child {
  position: absolute;
  height: 54.02%;
  width: 30.77%;
  top: 29.63%;
  right: -1.1%;
  bottom: 16.35%;
  left: 70.33%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.companies-sort {
  position: absolute;
  top: 0;
  left: 0;
  color: var(--no-dig-dark-blue, #011E34);
font-family: Nunito;
font-size: 20px;
font-style: normal;
font-weight: 400;
line-height: normal;
padding: 0.2rem;
}
.companies-vector-parent {
  position: relative;
  width: 91px;
  height: 27px;
  cursor: pointer;
}
.companies-group-wrapper {
  background-color: var(--color-whitesmoke);
  width: 112px;
  display: flex;
  flex-direction: column;
  padding: var(--padding-9xs);
  box-sizing: border-box;
  align-items: flex-start;
  justify-content: flex-start;
}
.companies-frame-container,
.companies-group-container,
.companies-reviews-parent {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.companies-group-container {
  background-color: var(--color-whitesmoke);
  flex-direction: column;
  padding: var(--padding-9xs);
}
.companies-frame-container,
.companies-reviews-parent {
  flex-direction: row;
}
.companies-frame-container {
  gap: var(--gap-xl);
  font-size: var(--h4-consumer-size);
  color: var(--nodig-dark-blue);
}
.companies-reviews-parent {
  width: 717px;
  height: 43px;
  gap: 320px;
  font-size: var(--h2-consumer-size);
  color: var(--mid-green);
  font-family: var(--h4-consumer);
}
.companies-div {
  position: relative;
  color: var(--no-dig-dark-blue, #011E34);

/* Body Text Consumer */
font-family: Catamaran;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 20px; /* 125% */
}
.companies-tile-icons {
  position: relative;
  width: 38px;
  height: 38px;
}
.companies-parent {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  color: var(--deep-blue-c);
}
.companies-div1,
.companies-im-very-satisfied {
  position: relative;
  line-height: 20px;
  display: inline-block;
  width: 148px;
  flex-shrink: 0;
}
.companies-im-very-satisfied {
  color: var(--nodig-dark-blue);
  width: 723px;
  height: 63px;
  color: var(--no-dig-dark-blue, #011E34);

/* Body Text Consumer */
font-family: Catamaran;
font-size: 18px;
font-style: normal;
font-weight: 500;
line-height: 20px; /* 125% */
}
.companies-frame-div {
  width: 723px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-base);
}
.companies-certifications-parent,
.companies-frame-group,
.companies-profile-inner {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.companies-frame-group {
  width: 717px;
  gap: var(--gap-base);
}
.companies-certifications-parent,
.companies-profile-inner {
  position: absolute;
  color: var(--dark-purple);
}
.companies-profile-inner {
  top: 745px;
  left: 434px;
  background-color: var(--white);
  border: 1px solid var(--color-gainsboro-200);
  box-sizing: border-box;
  width: 739px;
  padding: var(--padding-xs);
  font-family: var(--button-consumer);
  padding: 0.6rem;
}
.companies-certifications-parent {
  top: 628px;
  left: 97px;
  width: 203px;
  font-size: var(--h4-consumer-size);
}
.companies-repair {
  margin: 0;
  color: var(--no-dig-dark-blue, #011E34);

/* Body Text Consumer */
font-family: Catamaran;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 20px; /* 125% */
}
.companies-repair-10-cleaning-container {
  position: relative;
  font-size: var(--body-text-consumer-size);
  line-height: 20px;
  font-family: var(--button-consumer);
  color: var(--nodig-dark-blue);
  display: inline-block;
  width: 203px;
}
.companies-completed-jobs-parent {
  position: absolute;
  top: 714px;
  left: 97px;
  width: 203px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  font-size: var(--h4-consumer-size);
  color: var(--dark-purple);
}
.companies-button-base {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #48B1A3;
  cursor: pointer;
}
.companies-text {
  position: absolute;
  top: calc(50% - 18.5px);
  left: calc(50% - 93px);
  line-height: 120%;
  text-transform: uppercase;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 187px;
  height: 36px;
}
.companies-buttons1 {
  position: relative;
  width: 194px;
  height: 51px;
}
.companies-buttons {
  position: absolute;
  top: 148px;
  left: 108px;
  height: 51px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: var(--white);
  font-family: var(--button-text);
}
.companies-button-base1 {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: 5px;
  background-color: var(--mid-green);
  cursor: pointer;
}
.companies-buttons2 {
  position: absolute;
  top: 148px;
  left: 108px;
  border-radius: 20px;
  height: 51px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: var(--white);
  font-family: var(--button-text);
}
.companies-phone-android-phone-mobile-de-icon {
  position: relative;
  width: 24.2px;
  height: 24.16px;
}
.companies-phone-android-phone-mobile-de-parent {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 7px;
}
.companies-infoemailcom,
.companies-open-mail-icon {
  position: relative;
  flex-shrink: 0;
}
.companies-open-mail-icon {
  width: 26.67px;
  height: 24px;
  overflow: hidden;
}
.companies-infoemailcom {
  line-height: 20px;
  display: inline-block;
  width: 145px;
}
.companies-open-mail-parent {
  width: 176.2px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.companies-frame-parent3 {
  position: absolute;
  top: 244px;
  left: 108px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-xl);
  color: var(--nodig-dark-blue);
  font-family: var(--button-consumer);
}
.companies-image-5-icon {
  position: absolute;
  top: 449px;
  left: 92px;
  width: 208px;
  height: 150px;
  object-fit: cover;
}
.companies-gallery {
  position: absolute;
  top: 236px;
  left: 1319px;
  color: var(--mid-green, #48B1A3);

/* H2 Consumer */
font-family: Nunito;
font-size: 40px;
font-style: normal;
font-weight: 400;
line-height: normal;
text-transform: capitalize;
}
.companies-profile-child1 {
  position: absolute;
  top: 206px;
  left: 431px;
  width: 746px;
}
.companies-tile-icons13 {
  position: relative;
  width: 41px;
  height: 41px;
  overflow: hidden;
  flex-shrink: 0;
}
.companies-tile-icons-container,
.companies-tile-icons-group {
  background-color: var(--off-white-c);
  border: 1px solid var(--color-gainsboro-100);
  box-sizing: border-box;
  width: 181.75px;
  height: 130px;
  display: flex;
  flex-direction: column;
  padding: var(--padding-13xl);
  align-items: center;
  justify-content: center;
  gap: var(--gap-5xs);
  cursor: pointer;
}
.companies-tile-icons-container {
  background-color: var(--color-whitesmoke);
  color: var(--dark-purple);
}
.companies-union-icon {
  position: relative;
  width: 41px;
  height: 41px;
}
.companies-frame-parent4 {
  position: absolute;
  top: 206px;
  left: 433px;
  width: 739px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-9xs);
  font-size: var(--h4-consumer-size);
}
.companies-location-2-icon1,
.companies-open-mail-icon1,
.companies-phone-icon {
  position: absolute;
  top: 126px;
  left: 1.11px;
  width: 26.67px;
  height: 24px;
  overflow: hidden;
}
.companies-location-2-icon1,
.companies-phone-icon {
  top: 86px;
  left: 0;
}
.companies-phone-icon {
  top: 46px;
  left: 4.44px;
}
.companies-lorem-ipsum,
.companies-lorem-ipsum1 {
  position: absolute;
  top: 0;
  left: 3.33px;
  font-size: var(--body-large1-size);
  display: inline-block;
  width: 195.56px;
  height: 40px;
}
.companies-lorem-ipsum1 {
  left: 277.78px;
}
.companies-lorem-ipsum2,
.companies-lorem-ipsum3,
.companies-lorem-ipsum4,
.companies-lorem-ipsum5 {
  position: absolute;
  top: 46px;
  left: 47.78px;
  display: inline-block;
  width: 195.56px;
  height: 40px;
}
.companies-lorem-ipsum3,
.companies-lorem-ipsum4,
.companies-lorem-ipsum5 {
  left: 277.78px;
}
.companies-lorem-ipsum4,
.companies-lorem-ipsum5 {
  top: 86px;
}
.companies-lorem-ipsum5 {
  top: 126px;
}
.companies-lorem-ipsum10,
.companies-lorem-ipsum6,
.companies-lorem-ipsum7,
.companies-lorem-ipsum8,
.companies-lorem-ipsum9 {
  position: absolute;
  left: 951.11px;
  display: inline-block;
  width: 195.56px;
  height: 40px;
}
.companies-lorem-ipsum6 {
  top: 0;
  font-size: var(--body-large1-size);
}
.companies-lorem-ipsum10,
.companies-lorem-ipsum7,
.companies-lorem-ipsum8,
.companies-lorem-ipsum9 {
  top: 46px;
}
.companies-lorem-ipsum10,
.companies-lorem-ipsum8,
.companies-lorem-ipsum9 {
  top: 86px;
}
.companies-lorem-ipsum10,
.companies-lorem-ipsum9 {
  top: 126px;
}
.companies-lorem-ipsum10 {
  top: 0;
  left: 1225.56px;
  font-size: var(--body-large1-size);
}
.companies-lorem-ipsum11,
.companies-lorem-ipsum12,
.companies-lorem-ipsum13,
.companies-lorem-ipsum14,
.companies-lorem-ipsum15 {
  position: absolute;
  top: 46px;
  left: 1225.56px;
  display: inline-block;
  width: 195.56px;
  height: 40px;
}
.companies-lorem-ipsum12,
.companies-lorem-ipsum13,
.companies-lorem-ipsum14,
.companies-lorem-ipsum15 {
  top: 86px;
}
.companies-lorem-ipsum13,
.companies-lorem-ipsum14,
.companies-lorem-ipsum15 {
  top: 126px;
}
.companies-lorem-ipsum14,
.companies-lorem-ipsum15 {
  top: 86px;
  left: 47.78px;
}
.companies-lorem-ipsum15 {
  top: 126px;
}
.companies-new-1-icon,
.companies-open-mail-group {
  position: absolute;
  top: 190px;
  left: 60px;
  width: 1421.11px;
  height: 166px;
}
.companies-open-mail-group {
  position: absolute;
  top: 190px;
  left: 60px;
  width: 1421.11px;
  height: 166px;
  margin-left: 5rem;
}
.companies-new-1-icon {
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: cover;
}
.companies-logo {
  position: absolute;
  top: 218px;
  left: 674px;
  width: 296px;
  height: 109px;
}
.companies-footer,
.companies-hero-child,
.companies-profile {
  position: absolute;
  left: 0;
  width: 100vw;
}
.companies-footer {
  top: 1262px;
  height: 556px;
  background-image: url(/public/companies-footer@3x.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  font-size: var(--body-text-size);
  color: var(--white);
  font-family: var(--body-text);
}
.companies-hero-child,
.companies-profile {
  top: 347px;
  background-color: var(--color-whitesmoke);
  height: 1262px;
}
.companies-hero-child {
  left: 431px;
  background-color: var(--white);
  width: 100vw;
  height: 206px;
}
.companies-hero-item {
  position: absolute;
  top: 204px;
  left: 80px;
  border-radius: 50%;
  width: 263px;
  height: 263px;
  object-fit: cover;
}
.companies-div7 {
  position: absolute;
  top: 0;
  left: 0;
  line-height: 120%;
}
.companies-frame-wrapper,
.companies-tile-icons-parent2 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.companies-frame-wrapper {
  position: absolute;
  top: 0;
  left: 55px;
  width: 152px;
  height: 24px;
  align-items: center;
}
.companies-reviews1 {
  position: absolute;
  top: 27px;
  left: 58px;
  font-size: var(--body-text-size);
  line-height: 120%;
  color: var(--nodig-dark-blue);
  display: inline-block;
  width: 173px;
  height: 16.11px;
}
.companies-group-div {
  position: absolute;
  top: 478px;
  left: 507px;
  width: 231px;
  height: 43.11px;
  font-family: var(--body-text);
}
.companies-company-name {
  position: absolute;
  top: 362px;
  left: 488px;
  color: var(--no-dig-dark-blue, #011E34);
/* H1 Consumer */
font-family: Nunito;
font-size: 80px;
font-style: normal;
font-weight: 400;
line-height: normal;
}
.companies-verified-1 {
  position: absolute;
  height: 105.13%;
  width: 105.41%;
  top: 0;
  right: -2.7%;
  bottom: -5.13%;
  left: -2.7%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: cover;
}
.companies-verified-badge {
  position: absolute;
  top: 365px;
  left: 1310px;
  width: 148px;
  height: 156px;
  mix-blend-mode: normal;
}
.companies-magnifying-glass-glass-search-icon {
  position: relative;
  width: 24px;
  height: 24px;
  overflow: hidden;
  flex-shrink: 0;
}
.companies-how-can-we {
  position: relative;
}
.companies-magnifying-glass-glass-search-parent {
  position: absolute;
  top: 0;
  left: 0;
  background-color: var(--white);
  width: 279px;
  display: flex;
  flex-direction: row;
  padding: var(--padding-xl);
  box-sizing: border-box;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-3xs);
}
.companies-location-compass-1-arrow-comp-icon {
  position: relative;
  width: 15.86px;
  height: 16.85px;
}
.companies-location-compass-1-arrow-comp-parent,
.companies-text2 {
  position: absolute;
  display: flex;
  align-items: center;
}
.companies-location-compass-1-arrow-comp-parent {
  top: 0;
  left: 279px;
  background-color: var(--white);
  border-left: 1px solid var(--stroke-consumer);
  box-sizing: border-box;
  width: 279px;
  flex-direction: row;
  padding: var(--padding-xl);
  justify-content: flex-start;
  gap: var(--gap-3xs);
}
.companies-text2 {
  top: calc(50% - 18.5px);
  left: calc(50% - 93px);
  line-height: 20px;
  text-transform: uppercase;
  font-weight: 800;
  justify-content: center;
  width: 187px;
  height: 36px;
}
.companies-buttons5 {
  align-self: stretch;
  flex: 1;
  position: relative;
  background-color: var(--deep-corall);
}
.companies-buttons4,
.companies-search-bar {
  position: absolute;
  height: 67px;
}
.companies-buttons4 {
  top: 0;
  left: 558px;
  width: 194px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: var(--button-consumer-size);
  color: var(--white);
  font-family: var(--button-consumer);
}
.companies-search-bar {
  top: 140px;
  left: 424px;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  width: 752px;
  text-align: center;
  font-size: var(--h4-consumer-size);
  color: var(--grey-b);
}
.companies-hero {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 347px;
  background-image: url(/public/companies-hero@3x.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  font-size: var(--body-large-size);
  color: var(--deep-blue-c);
}
.companies-logo1 {
  position: relative;
  width: 206px;
  height: 76px;
}
.companies-about,
.companies-home,
.companies-search {
  position: relative;
  text-transform: uppercase;
  display: inline-block;
  width: 121px;
  flex-shrink: 0;
}
.companies-about,
.companies-search {
  width: 136px;
}
.companies-search {
  width: 140px;
}
.companies-main-navigation {
  height: 70px;
  display: flex;
  flex-direction: row;
  padding: 16px;
  box-sizing: border-box;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-xs);
}
.companies-text3 {
  position: absolute;
  top: calc(50% - 18.5px);
  left: calc(50% - 93px);
  line-height: 20px;
  text-transform: uppercase;
  font-weight: 800;
  display: inline-block;
  width: 187px;
  height: 36px;
}
.companies-buttons6 {
  background-color: var(--mid-green);
  height: 67px;
  display: flex;
  flex-direction: row;
  padding: 15px var(--padding-3xs);
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
}
.companies-text4 {
  position: relative;
  line-height: 20px;
  text-transform: uppercase;
  font-weight: 800;
  display: inline-block;
  width: 194px;
  height: 21px;
  flex-shrink: 0;
}
.companies-buttons8,
.companies-header,
.companies-header1,
.companies-header2 {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.companies-buttons8 {
  border: 1px solid var(--white);
  padding: 23px var(--padding-3xs);
  justify-content: center;
}
.companies-header,
.companies-header1,
.companies-header2 {
  justify-content: flex-start;
}
.companies-header2 {
  height: 83px;
  gap: var(--gap-xs);
  font-size: var(--button-consumer-size);
  font-family: var(--button-consumer);
}
.companies-header,
.companies-header1 {
  gap: 215px;
}
.companies-header {
  position: absolute;
  top: 0;
  left: 0;
  background-color: var(--sky-blue);
  border-bottom: 1px solid var(--white);
  box-sizing: border-box;
  width: 1500px;
  height: 108px;
  overflow: hidden;
  padding: 28px 80px;
  gap: 120px;
  text-align: center;
  font-size: var(--h4-consumer-size);
  color: var(--white);
}
.companies-profile-parent {
  position: relative;
  width: auto;
  height: 2165px;
  text-align: left;
  font-size: var(--body-text-consumer-size);
  color: var(--mid-green);
  font-family: var(--h4-consumer);
  overflow-x: hidden;
}
