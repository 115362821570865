.about-h1 {
    position: absolute;
    top: 412px;
    left: calc(50% - 678px);
    font-size: 80px;
    display: inline-block;
    width: 1357px;
    height: 125px;
  }
  .about-magnifying-glass-glass-search-icon {
    position: relative;
    width: 24px;
    height: 24px;
    overflow: hidden;
    flex-shrink: 0;
  }
  .about-how-can-we {
    position: relative;
  }
  .about-magnifying-glass-glass-search-parent {
    position: absolute;
    top: 0;
    left: 0;
    background-color: #fff;
    width: 279px;
    display: flex;
    flex-direction: row;
    padding: 20px;
    box-sizing: border-box;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    
  }
  .about-location-compass-1-arrow-comp-icon {
    position: relative;
    width: 15.86px;
    height: 16.85px;
  }
  .about-location-compass-1-arrow-comp-parent,
  .about-text {
    position: absolute;
    display: flex;
    align-items: center;
  }
  .about-location-compass-1-arrow-comp-parent {
    top: 0;
    left: 279px;
    background-color: #fff;
    border-left: 1px solid #d9d9d9;
    box-sizing: border-box;
    width: 279px;
    flex-direction: row;
    padding: 20px;
    justify-content: flex-start;
    gap: 10px;
  }
  .about-text {
    top: calc(50% - 18.5px);
    left: calc(50% - 93px);
    line-height: 20px;
    text-transform: uppercase;
    font-weight: 800;
    justify-content: center;
    width: 187px;
    height: 36px;
  }
  .about-buttons1 {
    align-self: stretch;
    flex: 1;
    position: relative;
    background-color: #fb7869;
    cursor: pointer;
    height: 65px;
  }
  .about-buttons,
  .about-search-bar {
    position: absolute;
    height: 65px;
  }
  .about-buttons {
    top: 0;
    left: 558px;
    width: 194px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    color: #fff;
    font-family: Catamaran;
  }
  .about-search-bar {
    top: 673px;
    left: 424px;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    width: 752px;
    color: #455e6c;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    
    
  }
  .about-welcome-to-nodigcom {
    position: absolute;
    top: 537px;
    left: calc(50% - 421px);
    font-size: 25px;
    font-family: Catamaran;
    display: inline-block;
    width: 841px;
    height: 123px;
  }
  .about-verified-badge {
    position: absolute;
    top: 156px;
    left: calc(50% - 91px);
    
    width: 182px;
    height: 191px;
  }
  .about-tile-icons {
    position: relative;
    width: 80px;
    height: 80px;
    overflow: hidden;
    flex-shrink: 0;
  }
  .about-trenchless {
    margin: 0;
    color: var(--no-dig-dark-blue, #011E34);
text-align: center;

/* Body Large Consumer */
font-family: Catamaran;
font-size: 25px;
font-style: normal;
font-weight: 400;
line-height: normal;
  }
  .about-title {
    position: relative;
    text-transform: uppercase;
  }
  .about-tile-icons-parent {
    width: 163px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 8px;
  }
  .about-service-icons {
    position: absolute;
    top: 813px;
    left: calc(50% - 256px);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 12px;
    color: #fff;
  }
  .about-hero {
    position: relative;
    top: 0;
    left: 0;
    width: 100vw;
    height: 1035px;
    background-image: url(/public/about-hero@3x.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
    font-size: 20px;
  }
  .about-nodigcom {
    color: #48b1a3;
  }
  .about-body,
  .about-h2 {
    position: relative;
    display: inline-block;
    width: 841px;
  }
  .about-h2 {
    color: var(--no-dig-dark-blue, #011E34);
text-align: center;

/* H2 Consumer */
font-family: Nunito;
font-size: 40px;
font-style: normal;
font-weight: 400;
line-height: normal;
text-transform: capitalize;
  }
  .about-body {
    font-size: 25px;
    height: 341px;
    flex-shrink: 0;
  }
  .about-buttons2,
  .about-text1,
  .about-welcome1 {
    display: flex;
    align-items: center;
  }
  .about-text1 {
    position: relative;
    line-height: 20px;
    text-transform: uppercase;
    font-weight: 800;
    justify-content: center;
    width: 200px;
    height: 36px;
    flex-shrink: 0;
  }
  .about-buttons2,
  .about-welcome1 {
    box-sizing: border-box;
  }
  .about-buttons2 {
    background-color: #48b1a3;
    height: 67px;
    flex-direction: row;
    padding: 15px 10px;
    justify-content: center;
    font-size: 18px;
    color: #fff;
    cursor: pointer;
  }
  .about-welcome1 {
    position: absolute;
    top: 54px;
    left: calc(50% - 441px);
    height: 530px;
    flex-direction: column;
    padding: 20px;
    justify-content: flex-start;
    gap: 20px;
  }
  .about-welcome {
    position: absolute;
    top: 1035px;
    left: 0;
    background-color: #fff;
    width: 100vw;
    height: 639px;
    overflow: hidden;
    font-family: Catamaran;
  }
  .about-h21 {
    position: relative;
    text-transform: capitalize;
    display: inline-block;
    width: 841px;
    z-index: 0;
    color: #011e34;
  }
  .about-georgia-de-lotz-ebb8fe-nztm-un-icon {
    position: relative;
    width: 405.64px;
    height: 430px;
    object-fit: cover;
  }
  .about-body1 {
    position: relative;
    font-size: 16px;
    line-height: 20px;
    font-family: Catamaran;
    display: inline-block;
    width: 446px;
    height: 46px;
    flex-shrink: 0;
  }
  .about-articles2,
  .about-articles3 {
    height: 85px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 12px;
  }
  .about-articles2 {
    background-color: #48b1a3;
    height: 622px;
    padding: 40px 20px 20px;
    box-sizing: border-box;
    gap: 32px;
  }
  .about-body2 {
    position: relative;
    font-size: 16px;
    line-height: 20px;
    font-family: Catamaran;
    display: inline-block;
    width: 446px;
    height: 48px;
    flex-shrink: 0;
  }
  .about-articles31 {
    height: 87px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 12px;
  }
  .about-body3 {
    position: relative;
    font-size: 16px;
    line-height: 20px;
    font-family: Catamaran;
    display: inline-block;
    width: 446px;
    height: 47px;
    flex-shrink: 0;
  }
  .about-articles11,
  .about-articles32 {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  .about-articles32 {
    height: 86px;
    flex-direction: column;
    gap: 12px;
  }
  .about-articles11 {
    flex-direction: row;
    gap: 20px;
    z-index: 1;
    font-size: 20px;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  .about-location-2-icon,
  .about-open-mail-icon,
  .about-phone-icon {
    position: absolute;
    top: 126px;
    left: 1.11px;
    width: 26.67px;
    height: 24px;
    overflow: hidden;
  }
  .about-location-2-icon,
  .about-phone-icon {
    top: 86px;
    left: 0;
  }
  .about-phone-icon {
    top: 46px;
    left: 4.44px;
  }
  .about-lorem-ipsum,
  .about-lorem-ipsum1 {
    position: absolute;
    top: 0;
    left: 3.33px;
    font-size: 25px;
    display: inline-block;
    width: 195.56px;
    height: 40px;
  }
  .about-lorem-ipsum1 {
    left: 277.78px;
  }
  .about-lorem-ipsum2,
  .about-lorem-ipsum3,
  .about-lorem-ipsum4,
  .about-lorem-ipsum5 {
    position: absolute;
    top: 46px;
    left: 47.78px;
    display: inline-block;
    width: 195.56px;
    height: 40px;
  }
  .about-lorem-ipsum3,
  .about-lorem-ipsum4,
  .about-lorem-ipsum5 {
    left: 277.78px;
  }
  .about-lorem-ipsum4,
  .about-lorem-ipsum5 {
    top: 86px;
  }
  .about-lorem-ipsum5 {
    top: 126px;
  }
  .about-lorem-ipsum10,
  .about-lorem-ipsum6,
  .about-lorem-ipsum7,
  .about-lorem-ipsum8,
  .about-lorem-ipsum9 {
    position: absolute;
    left: 951.11px;
    display: inline-block;
    width: 195.56px;
    height: 40px;
  }
  .about-lorem-ipsum6 {
    top: 0;
    font-size: 25px;
  }
  .about-lorem-ipsum10,
  .about-lorem-ipsum7,
  .about-lorem-ipsum8,
  .about-lorem-ipsum9 {
    top: 46px;
  }
  .about-lorem-ipsum10,
  .about-lorem-ipsum8,
  .about-lorem-ipsum9 {
    top: 86px;
  }
  .about-lorem-ipsum10,
  .about-lorem-ipsum9 {
    top: 126px;
  }
  .about-lorem-ipsum10 {
    top: 0;
    left: 1225.56px;
    font-size: 25px;
  }
  .about-lorem-ipsum11,
  .about-lorem-ipsum12,
  .about-lorem-ipsum13,
  .about-lorem-ipsum14,
  .about-lorem-ipsum15 {
    position: absolute;
    top: 46px;
    left: 1225.56px;
    display: inline-block;
    width: 195.56px;
    height: 40px;
  }
  .about-lorem-ipsum12,
  .about-lorem-ipsum13,
  .about-lorem-ipsum14,
  .about-lorem-ipsum15 {
    top: 86px;
  }
  .about-lorem-ipsum13,
  .about-lorem-ipsum14,
  .about-lorem-ipsum15 {
    top: 126px;
  }
  .about-lorem-ipsum14,
  .about-lorem-ipsum15 {
    top: 86px;
    left: 47.78px;
  }
  .about-lorem-ipsum15 {
    top: 126px;
  }
  .about-new-1-icon {
    position: absolute;
    top: 190px;
    left: 60px;
    width: 1421.11px;
    height: 166px;
  }
  .about-open-mail-parent{
    position: absolute;
    top: 190px;
    left: 60px;
    width: 1421.11px;
    height: 166px;
    margin-left: 5rem;
  }
  
  .about-new-1-icon {
    height: 100%;
    width: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 1rem;
    max-width: 100%;
    overflow: hidden;
    max-height: 100%;
    object-fit: cover;
    
    
  }
  .about-footer,
  .about-logo {
    position: absolute;
    top: 218px;
    left: 650px;
    width: 296px;
    height: 109px;
  }
  .about-footer {
    margin: 0 !important;
    top: 984px;
    left: 0;
    width: 100vw;
    height: 556px;
    background-image: url(/public/about-footer@3x.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
    z-index: 2;
    text-align: left;
    font-size: 17px;
    font-family: "Didact Gothic";
  }
  .about-articles,
  .about-buttons3 {
    display: flex;
    box-sizing: border-box;
    align-items: center;
  }
  .about-buttons3 {
    background-color: #48b1a3;
    height: 67px;
    flex-direction: row;
    padding: 15px 10px;
    justify-content: center;
    z-index: 3;
    font-size: 18px;
    font-family: Catamaran;
    cursor: pointer;
  }
  .about-articles {
    position: absolute;
    top: 3310px;
    left: 0;
    background-color: #f5f5f5;
    width: 100vw;
    flex-direction: column;
    padding: 80px;
    justify-content: flex-start;
    gap: 40px;
    color: #fff;
    /* padding: 0.5rem; */
  }
  .about-georgia-de-lotz-ebb8fe-nztm-un-icon3 {
    position: absolute;
    top: 135px;
    left: calc(50% - 535px);
    width: 1069px;
    height: 551px;
    object-fit: cover;
  }
  .about-button-play-button-television-icon {
    position: absolute;
    height: 20.16%;
    width: 9.21%;
    top: 39.98%;
    right: 45.41%;
    bottom: 39.86%;
    left: 45.38%;
    max-width: 100%;
    overflow: hidden;
    max-height: 100%;
  }
  .about-h22 {
    /* position: absolute;
    top: 52px;
    left: 379px; */
    padding-top: 3rem;
    text-transform: capitalize;
    display: inline-block;
    width: 841px;
    text-align: center;
  }
  .about-what-is-nodig-verified {
    position: absolute;
    top: 2462px;
    left: 0;
    background-color: #fff;
    width: 100vw;
    height: 848px;
  }
  .about-nodig-verified-child {
    position: absolute;
    top: 154px;
    left: 320px;
    border-radius: 20px;
    width: 80%;
    height: 539px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .about-verified-badge1 {
    position: absolute;
    top: 81px;
    left: 150px;
    width: 634px;
    height: 659px;

  }
  .about-works {
    color: #51bf5e;
  }
  .about-how-it-works-container {
    position: relative;
    text-transform: capitalize;
    display: inline-block;
    width: 561px;
    margin-left: -2rem;
  }
  .about-how-it-works2-child {
    position: relative;
    width: 46px;
    height: 46px;
  }
  .about-just-enter-what {
    position: relative;
    font-size: 16px;
    line-height: 20px;
    font-family: Catamaran;
    color: #FFF;

/* Body Text Consumer */
font-family: Catamaran;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 20px; /* 125% */
  }
  .about-search-for-nodig-verified-inst-parent {
    width: 634px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 8px;
  }
  .about-how-it-works2 {
    align-self: stretch;
    border-radius: 20px;
    background-color: transparent;
    height: 102px;
    display: flex;
    flex-direction: row;
    padding: 20px;
    box-sizing: border-box;
    align-items: center;
    justify-content: flex-start;
    gap: 12px;
  }
  .about-just-enter-what1,
  .about-just-enter-what3 {
    /* align-self: stretch; */
    position: relative;
    font-size: 16px;
    line-height: 20px;
    font-family: Catamaran;
  }
  .about-just-enter-what3 {
    font-size: 17px;
    line-height: 120%;
    font-family: "Didact Gothic";
    color: #002036;
    display: none;
  }
  .about-how-it-works1,
  .about-nodig-verified11 {
    display: flex;
    flex-direction: column;
  }
  .about-how-it-works1 {
    margin-top: -3rem;
    width: 712px;
    height: 550px;
    align-items: flex-start;
    justify-content: center;
    gap: 2px;
    font-size: 20px;
    background: #00000000;
    color: white;
    border: none;
    margin-left: -2rem;
  }
  .about-nodig-verified11 {
    position: absolute;
    top: 187px;
    left: 900px;
    align-items: center;
    justify-content: center;

  }
  .about-nodig-verified1 {
    position: absolute;
    top: 1674px;
    left: 0;
    background-color: #f5f5f5;
    width: 100vw;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 788px;
    text-align: left;
    color: #fff;
    
    /* margin-left: 5rem; */
    
  }
  .about-georgia-de-lotz-ebb8fe-nztm-un-icon4 {
    position: absolute;
    top: 3260px;
    left: 1296px;
    width: 0;
    height: 0;
    object-fit: cover;
  }
  .about-logo1 {
    position: relative;
    width: 300px;
    height: 76px;
    margin-left: -4.5rem;
  }
  .about-about,
  .about-home,
  .about-search {
    position: relative;
    text-transform: uppercase;
    display: inline-block;
    width: 121px;
    flex-shrink: 0;
    cursor: pointer;
  }
  .about-about,
  .about-search {
    width: 136px;
  }
  .about-search {
    width: 140px;
  }
  .about-main-navigation {
    height: 70px;
    display: flex;
    flex-direction: row;
    /* padding: 16px; */
    box-sizing: border-box;
    align-items: center;
    justify-content: flex-start;
    gap: 12px;
    
    
  }
  .about-text3 {
    position: absolute;
    top: calc(50% - 18.5px);
    left: calc(50% - 93px);
    line-height: 20px;
    text-transform: uppercase;
    font-weight: 800;
    display: inline-block;
    width: 210px;
    height: 36px;
    text-align: center;
    margin-left: -0.7rem;
  }
  .about-buttons5 {
    position: relative;
    width: 194px;
    height: 51px;
    cursor: pointer;
  }
  .about-buttons4 {
    background-color: #48b1a3;
    height: 67px;
    display: flex;
    flex-direction: row;
    padding: 15px 10px;
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
  }
  .about-text4 {
    position: relative;
    line-height: 20px;
    text-transform: uppercase;
    font-weight: 800;
    display: inline-block;
    width: 194px;
    height: 21px;
    flex-shrink: 0;
    text-align: center;
    text-decoration: none;
    color: white;
    
  }
  .about-buttons6,
  .about-header,
  .about-header1,
  .about-header2 {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  
  .about-buttons6 {
    border: 1px solid #fff;
    padding: 23px 10px;
    justify-content: center;
    cursor: pointer;
    height: 4.1rem;
  }
  .about-header,
  .about-header1,
  .about-header2 {
    justify-content: flex-start;
  }
  .about-header2 {
    height: 83px;
    gap: 12px;
    font-size: 18px;
    font-family: Catamaran;
  }
  .about-header,
  .about-header1 {
    gap: 215px;
  }
  .about-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #9dbbf9;
    border-bottom: 1px solid #fff;
    box-sizing: border-box;
    width: 100vw;
    height: 108px;
    overflow: hidden;
    padding: 28px 80px;
    gap: 120px;
    font-size: 20px;
    color: #fff;
    width: 100%;
  }
  .about-hero-parent {
    position: relative;
    width: auto;
    height: 4850px;
    text-align: center;
    font-size: 40px;
    color: #011e34;
    font-family: Nunito;
    overflow-x: hidden;
  }
  .about-input-zip-code{
    outline: none;
    border: none;
    font-size: 1.2rem;
  }