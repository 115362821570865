.search-listings-child {
  position: absolute;
  left: 0;
  background-color: var(--color-whitesmoke);
  width: 430px;
  height: 755px;
  
}
.container{
  position: relative;
}
.search-listings1 {
  /* position: absolute;
  top: 270px;
  left: 431px; */
  color: var(--no-dig-dark-blue, #011E34);
font-family: Nunito;
font-size: 40px;
font-style: normal;
font-weight: 700;
line-height: normal;
text-transform: capitalize;
text-align: center;
}
.search-location-2-icon,
.search-open-mail-icon,
.search-phone-icon {
  position: absolute;
  top: 126px;
  left: 1.11px;
  width: 26.67px;
  height: 24px;
  overflow: hidden;
}
.search-location-2-icon,
.search-phone-icon {
  top: 86px;
  left: 0;
}
.search-phone-icon {
  top: 46px;
  left: 4.44px;
}
.search-lorem-ipsum,
.search-lorem-ipsum1 {
  position: absolute;
  top: 0;
  left: 3.33px;
  font-size: 17px;
  display: inline-block;
  width: 195.56px;
  height: 40px;
}
.search-lorem-ipsum1 {
  left: 277.78px;
}
.search-lorem-ipsum2,
.search-lorem-ipsum3,
.search-lorem-ipsum4,
.search-lorem-ipsum5 {
  position: absolute;
  top: 46px;
  left: 47.78px;
  display: inline-block;
  width: 195.56px;
  height: 40px;
}
.search-lorem-ipsum3,
.search-lorem-ipsum4,
.search-lorem-ipsum5 {
  left: 277.78px;
}
.search-lorem-ipsum4,
.search-lorem-ipsum5 {
  top: 86px;
}
.search-lorem-ipsum5 {
  top: 126px;
}
.search-lorem-ipsum10,
.search-lorem-ipsum6,
.search-lorem-ipsum7,
.search-lorem-ipsum8,
.search-lorem-ipsum9 {
  position: absolute;
  left: 951.11px;
  display: inline-block;
  width: 195.56px;
  height: 40px;
}
.search-lorem-ipsum6 {
  top: 0;
  font-size: var(--body-large-consumer-size);
}
.search-lorem-ipsum10,
.search-lorem-ipsum7,
.search-lorem-ipsum8,
.search-lorem-ipsum9 {
  top: 46px;
}
.search-lorem-ipsum10,
.search-lorem-ipsum8,
.search-lorem-ipsum9 {
  top: 86px;
}
.search-lorem-ipsum10,
.search-lorem-ipsum9 {
  top: 126px;
}
.search-lorem-ipsum10 {
  top: 0;
  left: 1225.56px;
  font-size: var(--body-large-consumer-size);
}
.search-lorem-ipsum11,
.search-lorem-ipsum12,
.search-lorem-ipsum13,
.search-lorem-ipsum14,
.search-lorem-ipsum15 {
  position: absolute;
  top: 46px;
  left: 1225.56px;
  display: inline-block;
  width: 195.56px;
  height: 40px;
}
.search-lorem-ipsum12,
.search-lorem-ipsum13,
.search-lorem-ipsum14,
.search-lorem-ipsum15 {
  top: 86px;
}
.search-lorem-ipsum13,
.search-lorem-ipsum14,
.search-lorem-ipsum15 {
  top: 126px;
}
.search-lorem-ipsum14,
.search-lorem-ipsum15 {
  top: 86px;
  left: 47.78px;
}
.search-lorem-ipsum15 {
  top: 126px;
}
.search-new-1-icon,
.search-open-mail-parent {
  position: absolute;
  top: 190px;
  left: 134.44px;
  width: 100%;
  height: 166px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.main-searchPage{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: #F5F5F5;
}
.main-searchResSearch{
  /* position: absolute; */
  display: flex;
  flex-direction: column;
  margin-top: 0rem;
  /* border: 20px solid red; */
  margin-left: 0rem;
  overflow-y: scroll;
  height: 50rem;
  width: 100%; 
  overflow-x: hidden;
  margin-bottom: 5rem;
  /* justify-content: center;
  align-items: center; */
  /* background: #F5F5F5; */
}
.main-searchResSearch::-webkit-scrollbar { 
  display: none;  /* Safari and Chrome */
}
.search-new-1-icon {
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: cover;
}
.search-logo {
  position: absolute;
  top: 218px;
  left: 674px;
  width: 296px;
  height: 109px;
}
/* .search-footer{
  
  top: 218px;
  left: 674px;
  height: 109px;
} */
.main-searchResSearch{
  /* position: absolute;
  top:100px; */
  
}
.search-footer {
  position: absolute;
  top:1580px;
  left: 0;
  width: 100vw;
  height: 556px;
  z-index: 2;
  background-image: url(/public/search-footer@3x.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  font-size: var(--body-text-size);
  color: var(--white);
  font-family: var(--body-text);
}
.search-group-child {
  position: absolute;
  height: 54.02%;
  width: 30.77%;
  top: 29.63%;
  right: -1.1%;
  bottom: 16.35%;
  left: 70.33%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.search-sort {
  position: absolute;
  top: 0;
  left: 0;
  color: var(--no-dig-dark-blue, #011E34);
font-family: Nunito;
font-size: 20px;
font-style: normal;
font-weight: 400;
line-height: normal;
padding: 0.2rem;

}
.search-vector-parent {
  position: relative;
  width: 91px;
  height: 27px;
  padding-left: 0.5rem;
  
}
.search-group-wrapper {
  background-color: var(--white);
  width: 112px;
  display: flex;
  flex-direction: column;
  padding: var(--padding-9xs);
  box-sizing: border-box;
  align-items: flex-start;
  justify-content: flex-start;
}
.search-frame-parent,
.search-group-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.search-group-container {
  background-color: var(--white);
  flex-direction: column;
  padding: var(--padding-9xs);
}
.search-frame-parent {
  position: absolute;
  top: 260px;
  left: 940px;
  flex-direction: row;
  gap: var(--gap-xl);
}
.search-untitled-design-1-1 {
  position: relative;
  width: 90px;
  height: 90px;
  object-fit: cover;
  padding: 1rem;
}
.search-company-name {
  position: relative;
  text-transform: uppercase;
  display: inline-block;
  width: 258px;
  margin-left: -1rem;
  font-family: 'Nunito', sans-serif;
font-weight: 400px;
font-size: 20px;
line-height:27.28px;
}
.search-location-2-icon1 {
  position: relative;
  width: 26.67px;
  height: 24px;
  overflow: hidden;
  flex-shrink: 0;
}
.search-location-each {
  position: relative;
  line-height: 20px;
  display: inline-block;
  width: 227px;
  flex-shrink: 0;
  font-family: 'Nunito', sans-serif;
font-weight: 400;
font-size: 16px;
line-height: 20px;
}
.rating{
  font-family: 'Catamaran', sans-serif;
font-weight: 400;
font-size: 25px;
line-height: 41px;
}
.search-company-name-parent,
.search-location-2-parent {
  width: 258px;
  display: flex;
  gap: var(--gap-9xs);
}
.search-location-2-parent {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  font-size: var(--body-text-consumer-size);
  font-family: var(--button-consumer);
  margin-left: -1rem;
}
.search-company-name-parent {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  cursor: pointer;
}
.search-untitled-design-1-1-parent {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-xl);
}
.search-div{
  padding-left: 0.1rem;
  
}
.search-input-zip-code{
  outline: none;
  border: none;
  font-size: 1.2rem;
  width: 14rem;
}
.search-div,
.search-tile-icons {
  position: relative;
}
.search-tile-icons {
  width: 38px;
  height: 38px;
}
.search-tile-icons-parent {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.search-reviews {
  position: absolute;
  top: 38px;
  left: 7px;
  line-height: 20px;
  display: inline-block;
  width: 134px;
  height: 16px;
font-family: 'Catamaran', sans-serif;
font-weight: 400;
font-size: 16px;
line-height: 20px;
}
.group-border{
  
  /* border: 1px #E3E1DD; */
  
  border: px solid var(--color-gainsboro-100);
}
.search-frame-parent2 {
  position: relative;
  width: 152px;
  height: 54px;
  font-size: var(--body-text-consumer-size);
  color: var(--nodig-dark-blue);
}
.search-frame-wrapper1,
.search-parent {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.search-parent {
  width: 207px;
}
.search-frame-wrapper1 {
  font-size: var(--body-large-consumer-size);
  color: var(--deep-blue-c);
  font-family: var(--button-consumer);
}
.search-verified-1 {
  position: absolute;
  height: 110.81%;
  width: 111.43%;
  top: 0;
  right: -5.71%;
  bottom: -10.81%;
  left: 4rem;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: cover;
}
.search-verified-badge {
  position: relative;
  width: 70px;
  height: 74px;
  mix-blend-mode: normal;
}
.search-frame-parent1 {
  width: 697px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-17xl);
  padding: 0.5rem;
}
.search-phone-android-phone-mobile-de-icon {
  position: relative;
  width: 26.67px;
  height: 24px;
}
.search-covered-areas {
  position: relative;
  font-family: Catamaran;
font-size: 16px;
font-style: normal;
font-weight: 400;
color: var(--mid-green, #48B1A3);
text-align: center;
  line-height: 20px;
  display: inline-block;
  width: 145px;
}
.search-phone-android-phone-mobile-de-parent {
  width: 196px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.search-california-other-other {
  position: relative;
  font-family: Nunito;
font-size: 20px;
font-style: normal;
  color: var(--no-dig-dark-blue, #011E34);
text-align: center;
  display: inline-block;
  line-height: normal;
  width: 196px;
}
.search-frame-parent4,
.search-frame-wrapper2 {
  width: 196px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.search-frame-wrapper2 {
  flex-direction: row;
}
.search-wrench-crescent-tool-construc-icon {
  position: relative;
  width: 23.93px;
  height: 23.99px;
}
.search-search-frame-wrapper3 {
  width: 196px;
  height: 98px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.search-bag-dollar-bag-payment-cash-m-icon {
  position: relative;
  width: 22.16px;
  height: 24px;
}
.search-bag-dollar-bag-payment-cash-m-parent {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.search-free-estimates {
  align-self: stretch;
  position: relative;
  font-size: var(--h4-consumer-size);
  font-family: var(--h4-consumer);
  color: var(--nodig-dark-blue);
}
.search-frame-parent3,
.search-frame-parent6 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.search-frame-parent6 {
  align-self: stretch;
  flex: 1;
  flex-direction: column;
}
.search-frame-parent3 {
  border-top: 0.5px solid var(--color-darkgray);
  box-sizing: border-box;
  justify-content: space-between;
  width: 697px;
  flex-direction: row;
  padding: var(--padding-xl) 0 0;
  gap: var(--gap-35xl);
  text-align: center;
  font-size: var(--body-text-consumer-size);
  color: var(--mid-green);
  font-family: var(--button-consumer);
  margin-top: -3rem;
}
.search-frame-div {
  gap: var(--gap-21xl);
}
.search-frame-container,
.search-frame-div,
.search-frame-wrapper {
  width: 697px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.search-frame-wrapper {
  /* position: absolute;
  top: 0;
  left: 0; */
  /* margin-left: 25.8rem; */
  background-color: var(--white);
 
  border-top: 1px solid var(--color-gainsboro-200);
  border-right: 1px solid var(--color-gainsboro-200);
  border-left: 1px solid var(--color-gainsboro-200);
  box-sizing: border-box;
  width: 739px;
  padding: var(--padding-xl);
  height: 15rem;
  /* border-right: 1px solid rgba(227, 225, 221, 0.82);
  border-top: 1px solid rgba(227, 225, 221, 0.82);
  border-left: 1px solid rgba(227, 225, 221, 0.82); */
  
}
.main-fliter{
  width: 100%;
  
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.search-tile-icons4 {
  position: relative;
  width: 30px;
  height: 30px;
  overflow: hidden;
  flex-shrink: 0;;
}
.search-tile-icons-group:hover,.search-union-parent:hover,.search-tile-icons-parent1:hover{
  background: #F5F5F5;
  color: var(--dark-purple);
  border: 1px solid #E3E1DD;
}
.search-tile-icons-container,
.search-tile-icons-group {
  position: absolute;
  top: 0;
  left: 0;
  background-color: var(--white);
  border: 1px solid var(--color-gainsboro-100);
  box-sizing: border-box;
  width: 182px;
  height: 76px;
  display: flex;
  flex-direction: column;
  padding: var(--padding-3xs) var(--padding-13xl);
  align-items: center;
  justify-content: center;
  gap: var(--gap-5xs);
  cursor: pointer;
}
.search-tile-icons-container {
  left: 186px;
}
.search-tile-icons-container:hover{
  background: #F5F5F5;
  color: var(--dark-purple);
  border: 1px solid #E3E1DD;
}

.search-union-icon {
  position: relative;
  width: 30px;
  height: 30px;
}
.search-tile-icons-parent1,
.search-union-parent {
  position: absolute;
  top: 0;
  left: 372px;
  background-color: var(--white);
  border: 1px solid var(--color-gainsboro-100);
  box-sizing: border-box;
  width: 181px;
  height: 76px;
  display: flex;
  flex-direction: column;
  padding: var(--padding-3xs) var(--padding-13xl);
  align-items: center;
  justify-content: center;
  gap: var(--gap-5xs);
  cursor: pointer;
}
.search-tile-icons-parent1 {
  left: 557.25px;
  width: 181.75px;
}
.search-frame-group,
.search-frame-parent7 {
  /* position: absolute; */
  width: 739px;
}
.search-frame-parent7 {
  position: absolute; 
  top: 235px;
  left: 0; 
  margin-top: 2rem;
  height: 79px;
  color: var(--mid-green);
  /* border-right: 1px solid rgba(227, 225, 221, 0.82);
  border-bottom: 1px solid rgba(227, 225, 221, 0.82);
  border-left: 1px solid rgba(227, 225, 221, 0.82); */
  width: 46.35rem;
}
.search-frame-parent7-hover{
  position: absolute; 
  top: 235px;
  left: 0; 
  margin-top: 2rem;
  height: 79px;
  width: 46.35rem;
  color: var(--mid-green);
  border-right: 1px solid rgba(227, 225, 221, 0.82);
  border-bottom: 1px solid rgba(227, 225, 221, 0.82);
  border-left: 1px solid rgba(227, 225, 221, 0.82);
}
.search-frame-wrapper:hover{
  border-right: 1px solid rgba(227, 225, 221, 0.82);
  border-top: 1px solid rgba(227, 225, 221, 0.82);
  border-left: 1px solid rgba(227, 225, 221, 0.82);
}

.search-frame-group {
  /* border: 1px solid black; */
  position: relative;
  /* top: 315px;
  left: 415px; */
  /* margin-top: 315px; */
  margin-left: 27.1rem;
  margin-top: 6rem;
  
  

  
}
.search-frame-wrapper5,
.search-tile-icons-parent4 {
  position: absolute;
  top: 0;
  left: 0;
  background-color: var(--white);
  border: 1px solid var(--color-gainsboro-200);
  box-sizing: border-box;
  width: 739px;
  display: flex;
  flex-direction: column;
  padding: var(--padding-xl);
  align-items: flex-start;
  justify-content: center;
}
.search-tile-icons-parent4 {
  left: 186px;
  border: 1px solid var(--color-gainsboro-100);
  width: 182px;
  height: 76px;
  padding: var(--padding-3xs) var(--padding-13xl);
  align-items: center;
  gap: var(--gap-5xs);
}
.search-frame-parent15 {
  position: absolute;
  top: 271px;
  left: 0;
  width: 739px;
  height: 76px;
  color: var(--mid-green);
}
.search-group-div,
.search-listings-inner {
  position: absolute;
  top: 0;
  left: 0;
  width: 739px;
  height: 347px;
}
.search-listings-inner {
  top: 673px;
  left: 430px;
  border: 2px solid var(--sky-blue);
  box-sizing: border-box;
  width: 743px;
  height: 351px;
}
.search-frame-parent17 {
  position: absolute;
  top: 272px;
  left: 0;
  width: 739px;
  height: 76px;
}
.search-other-company-name {
  position: relative;
  text-decoration: underline;
  text-transform: uppercase;
  display: inline-block;
  width: 258px;
}
.search-frame-wrapper11 {
  position: absolute;
  top: 0;
  left: 0;
  background-color: var(--white);
  border: 1px solid var(--color-gainsboro-200);
  box-sizing: border-box;
  width: 739px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding: var(--padding-xl);
  align-items: flex-start;
  justify-content: center;
  color: var(--nodig-dark-blue);
}
.search-frame-parent16 {
  position: absolute;
  top: 1034px;
  left: 428px;
  width: 739px;
  height: 348px;
  color: var(--mid-green);
}
.search-another-company-wrapper {
  width: 258px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.search-frame-wrapper17 {
  width: 216px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  font-size: var(--h4-consumer-size);
  font-family: var(--h4-consumer);
}
.search-button-base {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: 5px;
  background-color: #48B1A3;
  cursor: pointer;
}
.search-text {
  position: absolute;
  top: calc(50% - 18.42px);
  left: calc(50% - 81px);
  line-height: 120%;
  text-transform: uppercase;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 162px;
  height: 36px;
}
.search-buttons1 {
  position: relative;
  width: 162px;
  height: 43px;
}
.search-buttons {
  border-radius: 20px;
  width: 152px;
  height: 31px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: var(--white);
  font-family: var(--button-text);
}
.search-phone-android-phone-mobile-de-icon3 {
  position: relative;
  width: 24.2px;
  height: 24.16px;
}
.search-phone-android-phone-mobile-de-parent1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 7px;
}
.search-infoemailcom {
  position: relative;
  line-height: 20px;
  display: inline-block;
  width: 145px;
  flex-shrink: 0;
}
.search-open-mail-group {
  width: 176.2px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.search-city-state {
  margin: 0;
}
.search-frame-parent26 {
  width: 296px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-xl);
}
.search-div4 {
  position: relative;
  line-height: 20px;
}
.search-parent1 {
  width: 152px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.search-john-doe,
.search-search-location,
.search-mmddyyyy {
  position: relative;
  line-height: 20px;
  display: inline-block;
  width: 89.15px;
  flex-shrink: 0;
}
.search-location,
.search-mmddyyyy {
  width: 78.01px;
}
.search-mmddyyyy {
  width: 100px;
}
.search-john-doe-parent {
  width: 376px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  color: var(--dark-purple);
}
.search-frame-parent27,
.search-frame-wrapper18 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.search-frame-parent27 {
  width: 376px;
}
.search-frame-wrapper18 {
  align-self: stretch;
  color: var(--deep-blue-c);
}
.search-im-very-satisfied {
  align-self: stretch;
  position: relative;
  line-height: 20px;
  color: var(--no-dig-dark-blue, #011E34);
/* Body Text Consumer */
font-family: Catamaran;
font-size: 17px;
font-style: normal;
font-weight: 400;
  }
  .search-frame-parent25 {
    position: absolute;
    top: 755px;
    left: 1175px;
    background-color: var(--white);
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    border: 2px solid  #9DBBF9;
    box-sizing: border-box;
    width: 330px;
    height:fit-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: var(--padding-xl);
    align-items: flex-start;
    justify-content: center;
    gap: var(--gap-xl);
    font-size: var(--body-text-consumer-size);
    font-family: var(--button-consumer);
    padding: 1rem;
  }
  .search-image-6-icon {
    position: absolute;
    top: 755px;
    left: 65px;
    width: 349px;
    
    height: fit-content;
    object-fit: cover;
  }
  .search-hero-child,
  .search-listings {
    position: absolute;
    top: 347px;
    left: 0;
    background-color: var(--color-whitesmoke);
    width: 100vw;
    height: 200px;
    overflow-y: scroll; 
     overflow-x: hidden;
  }
  .search-hero-child {
    background-color: var(--white);
    height: 206px;
  }
  .search-near-location,
  .search-top-companies {
    position: absolute;
    top: 362px;
    left: 427px;
    color: var(--no-dig-dark-blue, #011E34);
/* H1 Consumer */
font-family: Nunito;
font-size: 80px;
font-style: normal;
font-weight: 400;
line-height: normal;
}
.search-near-location {
  top: 466px;
  left: 442px;
  color: var(--no-dig-dark-blue, #011E34);

/* H2 Consumer */
font-family: Nunito;
font-size: 40px;
font-style: normal;
font-weight: 400;
line-height: normal;
text-transform: capitalize;
}
.search-verified-13 {
  display: flex;
width: 148px;
height: 156px;
flex-direction: column;
justify-content: center;
align-items: center;
flex-shrink: 0;
  object-fit: cover;
}
.search-verified-badge3 {
  position: absolute;
  top: 365px;
  left: 1310px;
  width: 148px;
  height: 156px;
  mix-blend-mode: normal;
}
.search-logo1 {
  position: relative;
  width: 206px;
  height: 76px;
}
.search-about,
.search-home,
.search-search {
  position: relative;
  text-transform: uppercase;
  display: inline-block;
  width: 121px;
  flex-shrink: 0;
}
.search-about,
.search-search {
  width: 136px;
}
.search-search {
  width: 140px;
}
.search-main-navigation {
  height: 70px;
  display: flex;
  flex-direction: row;
  padding: 16px;
  box-sizing: border-box;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-xs);
}
.search-text1 {
  position: absolute;
  top: calc(50% - 18.5px);
  left: calc(50% - 93px);
  line-height: 20px;
  text-transform: uppercase;
  font-weight: 800;
  display: inline-block;
  width: 187px;
  height: 36px;
}
.search-buttons3 {
  position: relative;
  width: 194px;
  height: 51px;
}
.search-buttons2 {
  background-color: var(--mid-green);
  height: 67px;
  display: flex;
  flex-direction: row;
  padding: 15px var(--padding-3xs);
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
}
.search-text2 {
  position: relative;
  line-height: 20px;
  text-transform: uppercase;
  font-weight: 800;
  display: inline-block;
  width: 194px;
  height: 21px;
  flex-shrink: 0;
}
.search-buttons4,
.search-header,
.search-header1,
.search-header2 {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.search-buttons4 {
  border: 1px solid var(--white);
  padding: 23px var(--padding-3xs);
  justify-content: center;
}
.search-header,
.search-header1,
.search-header2 {
  justify-content: flex-start;
}
.search-header2 {
  height: 83px;
  gap: var(--gap-xs);
  font-size: var(--button-consumer-size);
  font-family: var(--button-consumer);
}
.search-header,
.search-header1 {
  gap: 215px;
}
.search-header {
  position: absolute;
  top: 0;
  left: 0;
  background-color: var(--sky-blue);
  border-bottom: 1px solid var(--white);
  box-sizing: border-box;
  width: 1500px;
  height: 108px;
  overflow: hidden;
  padding: 28px 80px;
  gap: 120px;
  text-align: center;
  color: var(--white);
}
.search-magnifying-glass-glass-search-icon {
  position: relative;
  width: 24px;
  height: 24px;
  overflow: hidden;
  flex-shrink: 0;
}
.search-magnifying-glass-glass-search-parent {
  position: absolute;
  top: 0;
  left: 0;
  background-color: var(--white);
  width: 279px;
  display: flex;
  flex-direction: row;
  padding: var(--padding-xl);
  box-sizing: border-box;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-3xs);
  height: 4.2rem;
  padding-left: 0.5rem;
}
.search-location-compass-1-arrow-comp-icon {
  position: relative;
  width: 15.86px;
  height: 16.85px;
}
.search-location-compass-1-arrow-comp-parent,
.search-text3 {
  position: absolute;
  display: flex;
  align-items: center;
}
.search-location-compass-1-arrow-comp-parent {
  top: 0;
  left: 279px;
  background-color: var(--white);
  border-left: 1px solid var(--stroke-consumer);
  box-sizing: border-box;
  width: 279px;
  flex-direction: row;
  padding: var(--padding-xl);
  justify-content: flex-start;
  gap: var(--gap-3xs);
  height: 4.2rem;
  padding-left: 0.5rem;
  margin-left: 0.05rem;
}
.search-text3 {
  top: calc(50% - 18.5px);
  left: calc(50% - 93px);
  line-height: 20px;
  text-transform: uppercase;
  font-weight: 800;
  justify-content: center;
  width: 187px;
  height: 36px;
}
.search-buttons6 {
  align-self: stretch;
  flex: 1;
  position: relative;
  background-color: #FB7869;
  cursor: pointer;
}
.search-buttons5,
.search-search-bar {
  position: absolute;
  height: 67px;
}
.search-buttons5 {
  top: 0;
  left: 558px;
  width: 194px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: var(--button-consumer-size);
  color: var(--white);
  font-family: var(--button-consumer);
}
.search-search-bar {
  top: 140px;
  left: 424px;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  width: 752px;
  text-align: center;
  color: var(--grey-b);
  display: flex;
  justify-content: center;
  align-items: center;
  

}
.hero-down{
  display: flex;
  width: 60%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  /* padding-left: 22rem;
  padding-right: 5rem; */
  margin-top: 1rem;
  margin-left: 25%;
}
.p1-left-hero-down{
  color: var(--no-dig-dark-blue, #011E34);

/* H1 Consumer */
font-family: Nunito;
font-size: 80px;
font-style: normal;
font-weight: 400;
line-height: normal;

}
.p2-left-hero-down{
  color: var(--no-dig-dark-blue, #011E34);

  /* H2 Consumer */
  font-family: Nunito;
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
  margin-top: -1rem;
  margin-left: 0.7rem;

}
.search-hero {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 347px;
  background-image: url(/public/search-hero@3x.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.search-filter-child {
  position: absolute;

  left: 0;
  background-color: var(--color-whitesmoke);
  width: 100vw;
  height: 20px;
  /* overflow-y: scroll;  */
   overflow-x: hidden;
}
.search-listings-parent {
  position: absolute;
 /* top: 200px; */
 top:300px;
  width: 100%;
  height: 1770px;
  text-align: left;
  font-size: var(--h4-consumer-size);
  
  background-color: var(--color-whitesmoke);
  color: var(--nodig-dark-blue);
  font-family: var(--h4-consumer);
  overflow-x: hidden;
  /* display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; */
 padding-top:10px;
}
.search-company-name-side{
  position: relative;
  text-transform: uppercase;
  display: inline-block;
  width: 258px;
  
  
}