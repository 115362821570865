@import url("https://fonts.googleapis.com/css2?family=Maven+Pro:wght@500;600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Didact+Gothic:wght@400&display=swap");
body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  /* overflow-y: scroll; */
}
:root {
  /* fonts */
  --button-text: "Maven Pro";
  --body-large: "Didact Gothic";

  /* font sizes */
  --button-text-size: 16px;
  --body-large-size: 32px;
  --body-text-size: 17px;
  --h3-size: 30px;
  --body-large1-size: 25px;
  --h2-size: 45px;
  --h1-size: 75px;

  /* Colors */
  --black: #000;
  --off-white-c: #fdfdfd;
  --color-gray-100: #001e34;
  --deep-blue-c: #000b16;
  --yellow-b: #ffab00;
  --white: #fff;
  --color-whitesmoke: #f5f6f6;
  --color-steelblue-100: #1d8ece;
  --color-steelblue-200: #2e77bb;
  --color-lightskyblue-100: #6cc4ee;
  --color-mediumseagreen-100: #51bf5f;
  --color-deepskyblue: #27ade2;

  /* Gaps */
  --gap-xs: 12px;
  --gap-9xs: 4px;
  --gap-5xs: 8px;
  --gap-21xl: 40px;
  --gap-xl: 20px;
  --gap-base: 16px;
  --gap-61xl: 80px;

  /* Paddings */
  --padding-base: 16px;
  --padding-61xl: 80px;
  --padding-9xl: 28px;
  --padding-13xl: 32px;

  /* border radiuses */
  --br-8xs: 5px;
  --br-3xs: 10px;
  --br-xl: 20px;
}
