.than-just-leads {
    color: var(--white);
  }
  .more-than-just-container {
    position: relative;
    line-height: 120%;
    font-weight: 600;
    color: var(--color-mediumseagreen-100);
  }
  .tile-icons1 {
    position: relative;
    width: 57px;
    height: 57px;
  }
  .instance-parent,
  .tile-icons-group {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: var(--gap-5xs);
  }
  .instance-parent {
    flex-direction: column;
    align-items: flex-start;
    gap: var(--gap-xl);
    font-size: var(--h3-size);
  }
  .answer{
    padding-top: 5px;
    padding-bottom: 5px;
    margin-bottom: 10px;
  }
  .button-base2 {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: var(--br-8xs);
    background-color: var(--color-mediumseagreen-100);
    cursor: pointer;
  }
  .text8 {
    position: absolute;
    top: calc(50% - 10.5px);
    left: calc(50% - 68px);
    line-height: 120%;
    text-transform: uppercase;
    font-weight: 600;
    display: inline-block;
    width: 135px;
    height: 20px;
    text-decoration: none;
  }
  .buttons {
    position: relative;
    width: 194px;
    height: 51px;
    text-align: center;
    font-size: var(--button-text-size);
  }
  .benefits,
  .more-than-just-leads-parent {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .more-than-just-leads-parent {
    flex-direction: column;
    gap: 28px;
  }
  .benefits {
    position: absolute;
    top: 3480px;
    left: 1px;
    width: 100vw;
    flex-direction: row;
    padding: var(--padding-61xl);
    box-sizing: border-box;
    background-image: url(/public/benefits@3x.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
  }
  .tile-icons5 {
    position: relative;
    width: 108px;
    height: 108px;
    display: none;
  }
  .verified-badge {
    position: relative;
    width: 183px;
    height: 188px;
    margin-top: -8rem;
  }
  .basic,
  .full-use-of {
    position: relative;
    line-height: 120%;
  }
  .basic {
    font-size: var(--h3-size);
    font-weight: 500;
    text-align: left;
  }
  .full-use-of {
    font-family: var(--body-large);
    display: inline-block;
    width: 250px;
  }
  .buttons1 {
    position: relative;
    width: 194px;
    height: 51px;
    font-size: var(--button-text-size);
    color: var(--white);
  }
  .bronze,
  .tile-icons-parent2 {
    font-family: var(--button-text);
  }
  .tile-icons-parent2 {
    border-radius: var(--br-xl);
    background-color: rgba(255, 255, 255, 0.9);
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    /* height: 350px; */
    display: flex;
    flex-direction: column;
    padding: var(--padding-13xl);
    box-sizing: border-box;
    align-items: center;
    justify-content: flex-end;
    gap: var(--gap-base);
    color: var(--color-gray-100);
  }
  .bronze {
    font-size: var(--h3-size);
    font-weight: 500;
    text-align: left;
  }
  .bronze,
  .contact-form-tracking,
  .live-chat-tracking {
    position: relative;
    line-height: 120%;
  }
  .contact-form-tracking {
    display: inline-block;
    width: 227px;
  }
  .buttons2 {
    position: relative;
    width: 194px;
    height: 51px;
    font-size: var(--button-text-size);
    font-family: var(--button-text);
  }
  .tile-icons-parent3,
  .tile-icons-parent4,
  .tile-icons-parent5 {
    border-radius: var(--br-xl);
    background-color: rgba(21, 69, 107, 0.9);
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    height: 490px;
    display: flex;
    flex-direction: column;
    padding: var(--padding-13xl);
    box-sizing: border-box;
    align-items: center;
    justify-content: flex-end;
    gap: var(--gap-base);
  }
  .tile-icons-parent4,
  .tile-icons-parent5 {
    background-color: rgba(29, 142, 206, 0.9);
    height: 562px;
  }
  .tile-icons-parent5 {
    background-color: rgba(108, 196, 238, 0.9);
    height: 598px;
  }
  .frame-parent,
  .membership-levels {
    display: flex;
    justify-content: center;
  }
  .frame-parent {
    flex-direction: row;
    align-items: flex-start;
    gap: 32px;
    text-align: center;
    font-size: var(--body-text-size);
    color: var(--white);
    font-family: var(--body-large);
  }
  .membership-levels {
    margin-bottom: 100px;
    position: absolute;
    top: 4089px;
    left: 0;
    width: 100vw;
    flex-direction: column;
    padding: var(--padding-61xl);
    box-sizing: border-box;
    align-items: center;
    gap: 132px;
    background-image: url(/public/membership-levels@3x.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
    text-align: right;
    color: var(--color-gray-100);
  }
  .become-nodig-verified {
    position: relative;
    line-height: 120%;
    font-weight: 600;
    display: inline-block;
    width: 374px;
  }
  .group-child {
    position: absolute;
    top: 54.64px;
    left: 55.06px;
    width: 372px;
    height: 349px;
  }
  .lorem-ipsum-dolor {
    position: relative;
    line-height: 120%;
    display: inline-block;
    width: 428px;
  }
  .lorem-ipsum-dolor-sit-amet-co-wrapper {
    position: absolute;
    top: 66.98px;
    left: 329.24px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .down-arrow-grey2,
  .verified-badge4 {
    position: absolute;
    top: 241px;
    left: 266.25px;
    width: 77px;
    height: 73px;
  }
  .verified-badge4 {
    top: 0;
    left: 0;
    width: 324px;
    height: 333px;
  }
  .group-container {
    position: relative;
    width: 757.24px;
    height: 403.64px;
  }
  .input-field-base-rectangle1 {
    position: relative;
    border-radius: var(--br-3xs);
    background-color: var(--off-white-c);
    width: 400px;
    height: 38px;
    outline: none;
    border: none;
    font-size: 1.2rem;
    padding-left: 0.5rem;
  }
  .component-2 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: var(--gap-9xs);
  }
  .input-field-base-rectangle4 {
    align-self: stretch;
    position: relative;
    border-radius: var(--br-3xs);
    background-color: var(--off-white-c);
    height: 38px;
    outline: none;
    border: none;
    font-size: 1.2rem;
    padding-left: 0.5rem;
  }
  .component-4,
  .component-5 {
    width: 238px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: var(--gap-9xs);
  }
  .component-5 {
    width: 400px;
  }
  .input-field-base-rectangle6 {
    position: relative;
    border-radius: var(--br-3xs);
    background-color: var(--off-white-c);
    width: 40px;
    height: 40px;
  }
  .text18,
  .tile-icons9 {
    position: relative;
    flex-shrink: 0;
  }
  .text18 {
    line-height: 120%;
    display: inline-block;
    width: 327px;
  }
  .tile-icons9 {
    width: 28px;
    height: 28px;
    overflow: hidden;
  }
  .component-13 {
    width: 400px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: var(--gap-5xs);
  }
  .component-2-parent {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: var(--gap-base);
  }
  .buttons5 {
    position: relative;
    width: 194px;
    height: 51px;
    text-align: center;
    font-size: var(--button-text-size);
    font-family: var(--button-text);
  }
  .frame-group {
    border-radius: var(--br-xl);
    background-color: var(--color-steelblue-100);
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    display: flex;
    flex-direction: column;
    padding: var(--padding-9xl) var(--padding-61xl);
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-21xl);
  }
  .group-parent,
  .registration-form {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: var(--gap-21xl);
  }
  .group-parent {
    flex-direction: row;
    text-align: left;
    font-size: var(--body-text-size);
    font-family: var(--body-large);
  }
  .registration-form {
    position: absolute;
    top: 5900px;
    left: 0;
    width: 100vw;
    flex-direction: column;
    padding: var(--padding-61xl);
    box-sizing: border-box;
    background-image: url(/public/registration-form@3x.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
    text-align: center;
  }
  .how-it-works {
    position: absolute;
    top: 2005px;
    left: 0;
    background: radial-gradient(50% 50%at 50% 50%, #2e77bb, rgba(46, 119, 187, 0)),
      #fff;
    width: 1601px;
    height: 913px;
  }
  .group-item {
    position: absolute;
    top: 7px;
    left: 0;
    border-radius: 50%;
    background: linear-gradient(147.91deg, #1d8ece, rgba(39, 173, 226, 0));
    width: 372px;
    height: 349px;
  }
  .favpng-plumber-plumbing-hvac-d-icon {
    position: absolute;
    top: 0;
    left: 63px;
    width: 350px;
    height: 403px;
    object-fit: cover;
  }
  .ellipse-parent {
    position: relative;
    width: 413px;
    height: 403px;
  }
  .group-wrapper {
    display: flex;
    flex-direction: row;
    padding: var(--padding-9xl) var(--padding-61xl);
    align-items: flex-start;
    justify-content: flex-start;
  }
  .nodigcom {
    color: var(--color-deepskyblue);
  }
  .join-forces-with-nodigcom {
    margin: 0;
    /* margin-top: 2rem; */
  }
  .by-joining-our-container,
  .join-forces-with-container {
    position: relative;
    line-height: 120%;
    display: inline-block;
  }
  .join-forces-with-container {
    font-weight: 600;
    width: 841px;
  }
  .by-joining-our-container {
    font-size: var(--body-large-size);
    font-family: var(--body-large);
    width: 812px;
  }
  .frame-wrapper,
  .join-forces-with-nodigcom-th-parent {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .join-forces-with-nodigcom-th-parent {
    justify-content: flex-start;
    gap: var(--gap-xl);
  }
  .frame-wrapper {
    width: 827px;
    justify-content: center;
    
  }
  .frame-container {
    position: absolute;
    top: 285px;
    left: 83px;
    width: 100vw;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: var(--gap-21xl);
    margin-left: -5rem;
  }
  .works {
    color: var(--color-steelblue-200);
  }
  .how-it-works-container {
    position: relative;
    font-size: var(--h2-size);
    line-height: 120%;
    font-weight: 600;
    display: inline-block;
    width: 312px;
    flex-shrink: 0;
    color: #2e3438;
  }
  .frame-child {
    position: relative;
    border-radius: 50%;
    width: 128px;
    height: 128px;
    object-fit: cover;
  }
  .create-your-business,
  .sign-up-and {
    position: relative;
    line-height: 120%;
    display: inline-block;
  }
  .create-your-business {
    font-weight: 500;
    width: 299px;
  }
  .sign-up-and {
    font-size: var(--body-text-size);
    font-family: var(--body-large);
    color: var(--deep-blue-c);
    width: 312px;
  }
  .ellipse-group,
  .frame-parent1,
  .how-it-works-parent {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .ellipse-group {
    background: radial-gradient(
      50% 50%at 50% 50%,
      #acafb7,
      rgba(243, 244, 245, 0)
    );
    width: 312px;
    flex-direction: column;
    gap: var(--gap-5xs);
  }
  .frame-parent1,
  .how-it-works-parent {
    flex-direction: row;
    /* gap: var(--gap-61xl); */
  }
  .frame-parent1 {
    flex-direction: column;
    padding: var(--padding-9xl) var(--padding-61xl);
    align-items: flex-start;
    justify-content: flex-start;
  }
  .button-base8 {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: var(--br-8xs);
    background-color: var(--color-deepskyblue);
    cursor: pointer;
  }
  .how-it-works1 {
    position: absolute;
    top: 892px;
    left: calc(50% - 717px);
    border-radius: var(--br-xl);
    background-color: var(--color-whitesmoke);
    box-shadow: 8px 8px 16px rgba(0, 0, 0, 0.2);
    width: 1350px;
    padding: var(--padding-61xl) 0;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: var(--gap-21xl);
    text-align: center;
    font-size: var(--h3-size);
    color: var(--color-steelblue-200);
    margin-left: 1.5rem;
  }
  .intro,
  .new-2-icon {
    position: absolute;
    left: 0;
  }
  .intro {
    top: 917px;
    background-color: #a2c8d3;
    width: 100vw;
    height: 1088px;
    text-align: left;
  }
  .new-2-icon, .new-2-icon1{
    height: 100%;
    width: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    max-width: 100%;
    overflow: hidden;
    max-height: 100%;
    object-fit: cover;
  }
  
  .home-logo {
    position: relative;
    width: 220px;
    height: 81px;
  }
  .home1,
  .page-11,
  .page-21 {
    position: relative;
    line-height: 120%;
    display: inline-block;
    width: 121px;
    flex-shrink: 0;
    cursor: pointer;
  }
  .page-11,
  .page-21 {
    width: 136px;
  }
  .page-21 {
    width: 140px;
  }
  .main-navigation2 {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: row;
    padding: var(--padding-base);
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-xs);
  }
  .buttons8,
  .main-navigation-wrapper {
    position: relative;
    width: 453px;
    height: 70px;
  }
  .buttons8 {
    width: 194px;
    height: 51px;
  }
  .buttons7 {
    height: 51px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  .text22 {
    position: absolute;
    top: calc(50% - 10.5px);
    left: calc(50% - 67px);
    line-height: 120%;
    text-transform: uppercase;
    font-weight: 600;
    display: inline-block;
    width: 135px;
    height: 20px;
    color: white;
    text-decoration: none;
  }
  .button-base10 {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: var(--br-8xs);
    border: 1px solid var(--color-gray-100);
    box-sizing: border-box;
    cursor: pointer;
  }
  .buttons9 {
    position: relative;
    width: 194px;
    height: 51px;
    color: var(--color-gray-100);
  }
  .buttons-parent,
  .home-header,
  .main-navigation1 {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }
  .buttons-parent {
    width: 432px;
    height: 83px;
    padding: var(--padding-base);
    box-sizing: border-box;
    align-items: flex-start;
    gap: var(--gap-xs);
    font-size: var(--button-text-size);
    color: var(--white);
    font-family: var(--button-text);
  }
  .home-header,
  .main-navigation1 {
    align-items: center;
    gap: 60px;
  }
  .home-header {
    position: absolute;
    top: 0;
    left: 80px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    padding: var(--padding-9xl) var(--padding-61xl);
    box-sizing: border-box;
    gap: 120px;
    font-size: var(--body-large-size);
    font-family: var(--body-large);
  }
  .grow-your-business-container {
    position: absolute;
    top: 84px;
    left: 0;
    line-height: 120%;
    text-transform: uppercase;
    font-weight: 600;
    display: inline-block;
    width: 472px;
    height: 181px;
    /* margin-left: -5rem; */
  }
  .increase-your-visibility,
  .nodigcom-is-the {
    position: absolute;
    line-height: 120%;
    display: inline-block;
    width: 440px;
  }
  .nodigcom-is-the {
    top: 216px;
    left: 939px;
    font-size: var(--body-large-size);
    font-family: var(--body-large);
    height: 152px;
  }
  .increase-your-visibility {
    top: 84px;
    left: 929px;
    font-size: var(--h3-size);
    font-weight: 500;
    height: 79px;
  }
  .verified-badge5 {
    position: absolute;
    top: 0;
    left: 515px;
    
    width: 333px;
    height: 348px;
  }
  .grow-your-business-parent {
    position: absolute;
    top: 66px;
    left: 38px;
    width: 100%;
    height: 368px;
    text-align: left;
    margin-left: 5rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  .button-base11 {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: var(--br-8xs);
    background-color: #51bf5e;
    cursor: pointer;
    margin-left: -5rem;
  }
  .buttons10 {
    position: absolute;
    top: 389px;
    left: 50px;
    width: 194px;
    height: 51px;
    font-size: var(--button-text-size);
    color: var(--white);
    margin-left: 9.65rem;
  }
  .tile-icons10 {
    position: relative;
    width: 109px;
    height: 109px;
    overflow: hidden;
    flex-shrink: 0;
  }
  .title {
    position: relative;
    line-height: 120%;
    font-weight: 500;
  }
  .tile-icons-parent6 {
    width: 349px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: var(--gap-xs);
  }
  .text24 {
    align-self: stretch;
    position: relative;
    font-size: var(--body-text-size);
    line-height: 120%;
    font-family: var(--body-large);
  }
  .tile {
    border-radius: var(--br-xl);
    background-color: var(--off-white-c);
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    width: 348px;
    height: 348px;
    display: flex;
    flex-direction: column;
    padding: var(--padding-base);
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
    gap: var(--gap-5xs);
  }
  .hero-centre,
  .tile-parent {
    position: absolute;
    width: 100vw;
  }
  .tile-parent {
    top: 541px;
    left: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: var(--gap-base);
    font-size: var(--h3-size);
    margin-left: -3.5rem;
  }
  .hero-centre {
    top: 219px;
    left: 80px;
    height: 562px;
    font-size: var(--h1-size);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
  .hero {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 917px;
    background-image: url(/public/hero@3x.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
    text-align: center;
    color: var(--color-gray-100);
  }
  .what-our-members {
    position: relative;
    line-height: 120%;
    font-weight: 600;
  }
  .frame-child2,
  .tile-icons14 {
    position: relative;
    width: 52px;
    height: 52px;
  }
  .tile-icons14{
    cursor: pointer;
  }
  .frame-child2 {
    border-radius: 50%;
    width: 57px;
    height: 57px;
    object-fit: cover;
  }
  .john-smiths {
    position: absolute;
    top: 0;
    left: 0;
    line-height: 120%;
    display: inline-block;
    width: 173px;
    height: 40px;
  }
  .abc-company {
    margin: 0;
    color: var(--color-lightskyblue-100);
  }
  .managing-director-abc-container {
    position: absolute;
    top: 40px;
    left: 5px;
    font-size: var(--body-text-size);
    line-height: 120%;
    display: inline-block;
    width: 173px;
    height: 46px;
  }
  .john-smiths-parent,
  .tile-icons15 {
    position: relative;
    width: 178px;
    height: 86px;
  }
  .tile-icons15 {
    width: 38px;
    height: 38px;
  }
  .tile-icons-parent11 {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-5xs);
  }
  .im-very-satisfied {
    position: relative;
    font-size: var(--body-text-size);
    line-height: 120%;
    display: inline-block;
    width: 353px;
    height: 50px;
    flex-shrink: 0;
  }
  .frame-parent3,
  .frame-parent4,
  .tile-icons-parent10 {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .frame-parent4 {
    border-radius: var(--br-xl);
    background-color: var(--white);
    overflow: hidden;
    flex-direction: column;
    padding: var(--padding-13xl);
    gap: var(--gap-5xs);
  }
  .frame-parent3,
  .tile-icons-parent10 {
    flex-direction: row;
    gap: var(--gap-xl);
  }
  .tile-icons-parent10 {
    align-items: center;
    gap: var(--gap-21xl);
    font-size: var(--body-large-size);
    font-family: var(--body-large);
  }
  .ellipse-div,
  .frame-child6 {
    position: relative;
    border-radius: 50%;
    width: 14px;
    height: 14px;
  }
  .ellipse-div {
    background-color: var(--white);
  }
  .frame-child6 {
    background-color: #8fccec;
  }
  .what-our-members-say-parent {
    position: absolute;
    top: 2918px;
    left: 1px;
    background-color: var(--white);
    width: 100vw;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    padding: var(--padding-61xl);
    box-sizing: border-box;
    align-items: center;
    justify-content: flex-start;
    gap: var(--gap-21xl);
    color: var(--color-gray-100);
    margin-left: -0.8rem;
  }
  .how-much-does-container {
    position: absolute;
    top: 0;
    left: 0;
    line-height: 120%;
    display: inline-block;
    width: 340px;
    height: 40px;
  }
  .how-much-does-it-cost-wrapper {
    position: relative;
    width: 340px;
    height: 40px;
  }
  .frame-parent7,
  .group-frame {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin-top: 0.1rem;
  }
  .frame-parent7 {
    position: absolute;
    top: 0;
    left: 0;
    width: 1348.5px;
    justify-content: space-between;
  }
  .group-inner {
    position: absolute;
    top: 59.5px;
    left: -0.5px;
    border-top: 1px solid var(--color-steelblue-100);
    box-sizing: border-box;
    width: 1348.04px;
    height: 1px;
  }
  .it-is-free-container {
    position: absolute;
    top: 68px;
    left: 0;
    font-size: var(--body-text-size);
    line-height: 120%;
    display: inline-block;
    width: 100%;
    height: 60px;
   
  }
  .group-div {
    position: relative;
    width: 1348.5px;
    height: 111px;
  }
  .how-do-i-container {
    position: absolute;
    top: 0;
    left: 0;
    line-height: 120%;
    display: inline-block;
    width: 1000%;
    height: 40px;
    padding-top: 0.7rem;
  }
  .frame-parent8,
  .how-do-i-become-a-nodig-verifi-wrapper {
    position: relative;
    width: 647px;
    height: 40px;
  }
  .frame-parent8 {
    /* margin-top: -2rem; */
    width: 1348.5px;
    height: 100px;
  }
  .i-am-new-container {
    position: absolute;
    top: 0;
    left: 0;
    line-height: 120%;
    display: inline-block;
    width: 1123px;
    height: 40px;
    padding-top: 1.1rem;
  }
  .i-am-new-to-trenchless-repair-wrapper {
    position: relative;
    width: 1123px;
    height: 40px;
  }
  .faqs,
  .faqs-parent,
  .group-parent1 {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
  .group-parent1 {
    border-radius: var(--br-xl);
    background-color: var(--color-whitesmoke);
    width: 1411px;
    overflow: hidden;
    padding: var(--padding-13xl);
    box-sizing: border-box;
    align-items: flex-start;
    gap: var(--gap-xl);
    font-size: var(--body-large-size);
    font-family: var(--body-large);
    
  }
  .faqs,
  .faqs-parent {
    align-items: center;
  }
  .faqs-parent {
    padding: var(--padding-9xl) var(--padding-61xl);
    gap: var(--gap-21xl);
  }
  .faqs {
    position: absolute;
    top: 5000px;
    height: fit-content;
    left: 0;
    width: 100vw;
    overflow: hidden;
    padding: var(--padding-61xl);
    box-sizing: border-box;
    background-image: url(/public/faqs@3x.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
    color: var(--deep-blue-c);
  }
  .location-2-icon,
  .open-mail-icon,
  .phone-icon {
    position: absolute;
    top: 126px;
    left: 1.11px;
    width: 26.67px;
    height: 24px;
    overflow: hidden;
  }
  .location-2-icon,
  .phone-icon {
    top: 86px;
    left: 0;
  }
  .phone-icon {
    top: 46px;
    left: 4.44px;
  }
  .lorem-ipsum,
  .lorem-ipsum1 {
    position: absolute;
    top: 0;
    left: 3.33px;
    font-size: var(--body-large1-size);
    display: inline-block;
    width: 195.56px;
    height: 40px;
  }
  .lorem-ipsum1 {
    left: 277.78px;
  }
  .lorem-ipsum2,
  .lorem-ipsum3,
  .lorem-ipsum4,
  .lorem-ipsum5 {
    position: absolute;
    top: 46px;
    left: 47.78px;
    display: inline-block;
    width: 195.56px;
    height: 40px;
  }
  .lorem-ipsum3,
  .lorem-ipsum4,
  .lorem-ipsum5 {
    left: 277.78px;
  }
  .lorem-ipsum4,
  .lorem-ipsum5 {
    top: 86px;
  }
  .lorem-ipsum5 {
    top: 126px;
  }
  .lorem-ipsum10,
  .lorem-ipsum6,
  .lorem-ipsum7,
  .lorem-ipsum8,
  .lorem-ipsum9 {
    position: absolute;
    left: 951.11px;
    display: inline-block;
    width: 195.56px;
    height: 40px;
  }
  .lorem-ipsum6 {
    top: 0;
    font-size: var(--body-large1-size);
  }
  .lorem-ipsum10,
  .lorem-ipsum7,
  .lorem-ipsum8,
  .lorem-ipsum9 {
    top: 46px;
  }
  .lorem-ipsum10,
  .lorem-ipsum8,
  .lorem-ipsum9 {
    top: 86px;
  }
  .lorem-ipsum10,
  .lorem-ipsum9 {
    top: 126px;
  }
  .lorem-ipsum10 {
    top: 0;
    left: 1225.56px;
    font-size: var(--body-large1-size);
  }
  .lorem-ipsum11,
  .lorem-ipsum12,
  .lorem-ipsum13,
  .lorem-ipsum14,
  .lorem-ipsum15 {
    position: absolute;
    top: 46px;
    left: 1225.56px;
    display: inline-block;
    width: 195.56px;
    height: 40px;
  }
  .lorem-ipsum12,
  .lorem-ipsum13,
  .lorem-ipsum14,
  .lorem-ipsum15 {
    top: 86px;
  }
  .lorem-ipsum13,
  .lorem-ipsum14,
  .lorem-ipsum15 {
    top: 126px;
  }
  .lorem-ipsum14,
  .lorem-ipsum15 {
    top: 86px;
    left: 47.78px;
  }
  .lorem-ipsum15 {
    top: 126px;
  }
  .open-mail-parent {
    position: absolute;
    top: 100px;
    left:60px;
    width: 1421.11px;
    height: 166px;
    right: 40px;
    margin-left: 5rem;
  }
  .group-parent2,
  .logo1 {
    position: absolute;
    top: 120px;
    left: 40%;
    width: 296px;
    height: 109px;
  }
  .group-parent2 {
    top: 6780px;
    left: 0;
    width: 100vw;
    height: 571px;
    background-image: url(/public/frame-32@3x.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
    font-size: var(--body-text-size);
    font-family: var(--body-large);
  }
  .benefits-parent {
    position: relative;
    width: 100vw;
    height: 7180px;
    text-align: left;
    font-size: var(--h2-size);
    color: var(--white);
    font-family: var(--button-text);
    overflow-x: hidden;
    overflow-y: hidden;
  }
  